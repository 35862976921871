:root {
	--header_pic_width: 50%;
	--header_cover_flex_dir: column;
	--qa_card_main_dimen: 84px;
	--item_card_height: 22%;
	--item_card_height_min: 116px;
	--item_card_height_max: 24%;

	--section_cover_background: linear-gradient(
		60deg,
		rgba(0, 0, 0, 0.7),
		rgba(0, 0, 0, 0.5),
		rgba(0, 0, 0, 0)
	);
}

table {
	border-collapse: collapse;
}

.admin_home_container {
	color: #595959;
}

.adm_links_container {
	background-color: whitesmoke;
	font-size: 1rem;
	display: flex;
	align-items: center;
	justify-content: center;
	line-height: 1.2em;
	padding: 1rem 2rem;
}

.admin_links,
.admin_links:visited {
	color: var(--col_blue_link);
	margin: 0 4px;
}

.admin_links:hover {
	border-color: currentColor;
}

.adm_links_container > a,
.adm_links_container > a:visited {
	color: var(--col_blue_link);
	margin: 0 10px;
	font-size: 0.9rem;
}

.admin_dash_header {
	display: flex;
	flex-direction: var(--header_cover_flex_dir);
	height: var(--hero_height_small);
}

.admin_dash_header_pic {
	flex-basis: var(--header_pic_width);
	height: 100%;
}

.admin_header_content {
	flex-basis: var(--header_pic_width);
	display: flex;
	flex-direction: column;
	justify-items: center;
	padding: 3rem 2rem 2rem;
	background-color: white;
}

.admin_header_info {
	margin: auto 0;
}

.adm_header_info_subtitle {
	/* font-size: var(--head_font_size); */
	font-weight: var(--head_font_thin);
}

.admin_sections_container {
	min-height: 70vh;
}

.adm_dash_action_card_container {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

/* Quick Action Card Styles */
.qa_card_container {
	min-width: 300px;
	max-width: 360px;
	margin: 4px 16px 4px 4px;
}

.qa_card_main {
	width: 100%;
	height: var(--qa_card_main_dimen);
	display: flex;
	box-shadow: 0px 0px 2px rgba(34, 34, 34, 0.102);
}

.qa_card_icon {
	width: var(--qa_card_main_dimen);
	height: var(--qa_card_main_dimen);
	border-radius: 6px;
	box-shadow: 2px 2px 6px rgba(34, 34, 34, 0.102);
}

.qa_card_info {
	margin: 3px 0;
	padding: 1vh 2vh;
	/* flex-basis: 80%; */
	flex-grow: 1;
	border-radius: 0 4px 4px 0;
	background-color: white;
}

.qa_card_count {
	margin: 2px 0;
	font-size: 2rem;
	line-height: 1.1em;
}

.qa_card_label {
	margin: 2px 0;
	font-size: 0.9rem;
	line-height: 1.1em;
	font-weight: 500;
}

.qa_card_actions {
	display: flex;
	justify-content: space-between;
	font-size: 0.8rem;
}

.qa_card_cta_secondary {
	color: currentColor;
}

.qa_card_cta_primary {
	color: currentColor;
}

/* End of Quick Action Card Styles */

/* Sectional Card Styles */
.adm_booking_reqs_card {
	min-height: 480px;
	height: 50vh;
	display: flex;
	margin: 6vh 2vh;
	background-color: #f5f5f5;
	box-shadow: 3px 3px 4px rgba(34, 34, 34, 0.05);
}

.adm_booking_reqs_cover_container {
	width: 30%;
	height: 100%;
	display: grid;
}

.adm_booking_reqs_cover_pic {
	height: 100%;
	grid-column: 1/-1;
	grid-row: 1/-1;
	z-index: 0;
	filter: blur(2px) saturate(150%);
	object-fit: cover;
	/* object-position: 0; */
}

.adm_booking_reqs_cover_link {
	height: 100%;
	grid-column: 1/-1;
	grid-row: 1/-1;
	z-index: 1;
}

.adm_booking_reqs_cover_info {
	height: 100%;
	padding: 2vh;

	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(4, 1fr);

	background: var(--section_cover_background);
	color: rgba(245, 245, 245, 0.75);
}

.adm_book_reqs_card_label {
	grid-column: 1/-1;
	grid-row: 1/2;
	margin: 0;
}

.adm_book_reqs_card_counter {
	align-self: end;
	justify-self: end;
	grid-column: -1/1;
	grid-row: -1/3;
	margin: 0;
}

.adm_booking_reqs_list_container {
	width: 70%;
	padding: 0 8px;
	background-color: whitesmoke;
	/* scroll-behavior: smooth; */
	overflow-y: scroll;
	scroll-snap-type: y mandatory;
}

.adm_booking_reqs_list {
	margin: 0;
	height: 100%;
	/* scroll-behavior: smooth; */
}

.adm_booking_reqs_list_item {
	width: 100%;
	height: var(--item_card_height);
	min-height: var(--item_card_height_min);
	max-height: var(--item_card_height_max);
	margin: 4px 8px 4px 4px;
	display: flex;
	background-color: white;
	align-items: stretch;
	justify-content: space-between;
	scroll-snap-align: start;
}

.reqs_list_item_info {
	flex-grow: 1;
	padding: 8px 1rem;
	display: flex;
	flex-direction: column;
}

.rli_prop_name {
	margin: 0 0 4px;
	font-size: 1.3rem;
	line-height: 1.4em;
	font-weight: 700;
}

.rli_book_deets {
	margin: 0;
	font-size: 0.9rem;
	line-height: 1.4em;
}

.rli_user_name {
	margin: auto 0 0 0;
	font-size: 0.9rem;
	line-height: 1.4em;
}

.reqs_list_item_cost {
	color: #4ace8c;
	font-size: 2rem;
	font-weight: 700;
	align-self: flex-end;
	margin: 1vh 2vh;
}

.reqs_list_item_actions {
	width: 48px;
	height: 100%;
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-content: space-between;
}

.reqs_list_item_action_approve {
	height: 50%;
	background-color: white;
	color: var(--col_green);
	font-size: 2rem;
	box-shadow: none;
	border-left: solid 0.5px rgba(34, 34, 34, 0.2);
	/* box-shadow: 1px 1px 3px rgba(34, 34, 34, 0.1); */
}

.reqs_list_item_action_approve:hover {
	background-color: var(--col_green);
	color: white;
}

.reqs_list_item_action_deny {
	height: 50%;
	background-color: white;
	color: var(--col_red);
	font-size: 2rem;
	box-shadow: none;
	border-left: solid 0.5px rgba(34, 34, 34, 0.2);
}

.reqs_list_item_action_deny:hover {
	background-color: var(--col_red);
	color: white;
}

.reqs_list_item_action_send_link {
	height: 100%;
	background-color: white;
	color: var(--col_green);
	text-orientation: sideways;
	box-shadow: none;
	border-left: solid 0.5px rgba(34, 34, 34, 0.2);
}

.reqs_list_item_action_send_link:hover {
	background-color: var(--col_green);
	color: white;
}

.team_card {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.team_cover_container {
	width: 40%;
}

.team_list_container {
	width: 60%;
	display: flex;
	flex-direction: column;
	overflow-y: none;
}

.team_list {
	height: 94%;
	overflow-y: scroll;
}

.team_list_item {
	margin: 1vh 12px 1vh 8px;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	background-color: white;
	border-radius: 3px;
	border-right: solid 4px var(--col_green);
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.team_list_item_info {
	display: flex;
	flex-direction: column;
	padding: 8px 12px;
}

.team_role_info {
	font-size: 0.9rem;
	line-height: 2em;
}

.team_role {
	color: var(--col_green);
	text-transform: uppercase;
}

.team_status {
	margin-left: 1rem;
}

.team_member_name {
	font-size: 1.5rem;
	margin: 1rem 0 6px;
}

.team_member_contact {
	font-size: 0.9rem;
	line-height: 2em;
}

.team_list_item_actions {
	height: 100%;
	width: 48px;
	display: flex;
	flex-direction: column;
}

.team_cta {
	color: var(--col_blue_link);
	margin: auto 4px 4px auto;
}
/* End of Sectional Card Styles */

/* Styles for Lists of Managing */
.booki_search {
	margin-bottom: 1rem;

	background-color: white;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.search_header {
	display: flex !important;
	align-items: baseline !important;
	padding: 2vh !important;
	margin: 0 0 1vh 0 !important;
}

.booki_search_title,
.booki_search_count {
	font-size: 1.4rem;
	line-height: 1.2em;
	margin: 0;
}

.booki_search_bar {
	/* height: 5rem; */
	padding: 1rem 1.4rem;
}

.booki_search_fields {
	display: flex;
	align-items: flex-end;
}

.booki_search_field_container {
	margin: 4px 8px 2px;
}

.booki_search_section {
	display: flex;
	align-items: flex-end;
	margin: 1vh 0;
}

.booki_search_active {
	font-size: 1rem;
}

.booki_search_active_filter {
	margin: 0 4px;
	font-size: 0.9rem;
	padding: 0.1em 1em;
	background-color: rgb(230, 230, 230);
	border-radius: 40px;
}

.booki_list {
	box-shadow: 0 2px 4px 0 rgba(40, 40, 40, 0.1);
	margin-bottom: 2vh;
}

.admin_manager_lister {
	margin: 0;
}

.admin_manager_lister:nth-child(even) {
	background-color: #f5f5f5;
}

.admin_manager_list_item {
	display: flex;
	background-color: #fafafa;
	justify-content: space-between;
	align-items: center;
	font-size: 0.9rem;
	border: solid 2px rgba(238, 238, 238, 0.5);
}

.admin_manager_list_item:hover {
	border-radius: 3px;
	border-color: var(--col_pep_1_50);
	background-color: var(--col_pep_1_25);
	box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.item_header {
	min-height: 3rem;
	font-weight: 700;
	align-items: flex-end;
	border-bottom: solid 2px rgba(160, 160, 160, 0.2);
}

.list_item_col {
	margin-right: 8px;
	padding: 2px 4px;
	text-align: left;
	/* border-left: solid 0.5px rgba(185, 185, 185, 0.5);
	border-right: solid 0.5px rgba(185, 185, 185, 0.5); */
}

.booki_list_item_link,
.booki_list_item_link:visited {
	color: var(--col_blue_link);
}

.booki_list_item_link:hover {
	text-decoration: underline;
}

.booki_list_item_info {
}

.booki_id {
	width: 16%;
	padding-right: 8px;
	text-align: start;
	word-wrap: break-word;
}

.booki_prop_name {
	width: 16%;
}

.booki_dates {
	width: 25%;
}

.booki_guests {
	width: 5%;
}

.booki_status {
	width: 15%;
}

.booki_customer {
	width: 22%;
	margin: 0 0 0 8px;
}
/* End of Styles for Lists of Managing Bookings*/

/* Start of styles for lists to Manage Users */
.users_container {
}

.users_lister {
	/* background-color: aliceblue; */
}

.users_search {
	margin-bottom: 2vh;
	background-color: white;
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.users_lister_table {
	width: 100%;
}

.admin_manager_table_head {
	display: flex;
	min-height: 5vh;
	align-items: flex-end;
	background-color: white;
	border-bottom: solid 2px rgba(160, 160, 160, 0.25);
	/* box-shadow: 2px 0px 2px 0 rgba(0, 0, 0, 0.15); */
	font-size: 0.9rem;
	font-weight: 700;
}

.admin_manager_table_row {
	display: flex;
	background-color: #fafafa;
	/* background-color: #f5f5f5; */
	align-items: flex-start;
	font-size: 0.9rem;
	padding: 4px 0;
	border: solid 2px rgba(238, 238, 238, 0.5);
}

.admin_manager_table_row:nth-child(even) {
	background-color: #f1f1f1;
}

.admin_manager_table_row:hover {
	border-radius: 3px;
	border-color: var(--col_pep_1_50);
	background-color: var(--col_pep_1_25);
	box-shadow: 2px 3px 6px 0 rgba(0, 0, 0, 0.15);
}

.users_list_item {
}

.users_list_col {
	margin-right: 8px;
	padding: 2px 4px 2px 8px;
	text-align: start;
	/* border-left: solid 0.5px rgba(185, 185, 185, 0.5);
	border-right: solid 0.5px rgba(185, 185, 185, 0.5); */
}

.users_list_link,
.users_list_link:visited {
	color: var(--col_blue_link);
}

.users_list_link:hover {
	border-bottom: solid 2px var(--col_blue_link);
}

.user_list_sno {
	width: 6%;
	text-align: end;
}

.users_list_name {
	width: 20%;
}

.users_list_phone {
	width: 20%;
	text-decoration: underline;
}

.users_list_mail {
	width: 32%;
	text-decoration: underline;
}

.user_list_booki_count {
	width: 8%;
}

.users_list_role {
	width: 14%;
}
/* End of styles for lists to Manage Users */

@media only screen and (min-width: 481px) {
	:root {
		--header_cover_flex_dir: row;
		--header_pic_width: 50%;
	}
}
