:root {
	--flex_direction: row;
	--venue_card_height_min: 340px;
	--venue_card_width_min: 280px;
	--venue_card_hov_scale: 1.025;
	--prop_list_grid_cols: repeat(
		auto-fit,
		minmax(calc(var(--venue_card_width_min) * var(--venue_card_hov_scale)), 1fr)
	);
	--icon_card_size: 80px;
}

.discover_hero {
	height: var(--hero_height_small);
}

/********************************************************************
PROPERTY FILTER TOOLBAR 
*********************************************************************/
.venue_searchbar_container {
	/* outline: solid 1px whitesmoke; */
	padding: 0.5rem 0;
}

.search_fields {
	display: flex;
	flex-direction: var(--flex_direction);
	justify-content: space-between;
	align-items: center;
}

.search_fields_main {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
}

.venue_searchbar_field_container {
	margin: 4px 6px;
}

.searchbar_active {
	flex-wrap: wrap;
	align-items: center;
	align-content: center;
}

.venue_searchbar_active_filter {
	display: inline-block;
	margin: 0 4px 8px;
	font-size: 0.9rem;
	padding: 0.1em 1em;
	background-color: #fafafa;
	border-radius: 40px;
	box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.2);
	cursor: default;
}

.venue_searchbar_active_filter:hover {
	box-shadow: 0 6px 12px -2px #2b2b2b36;
}

.venue_sort_btn {
	margin: 6px 8px;
	padding: 10px 12px;
	border-radius: 4px;
	/* color: #000; */
	box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.1);
}

.venue_sort_btn_active {
	color: var(--col_pep_3);
	background-color: white;
	border-radius: 6px;
	box-shadow: 1px 1px 3px rgba(50, 50, 50, 0.2);
}

.toolbar_container {
	display: flex;
	flex-direction: var(--flex_direction);
	justify-content: space-between;
	align-items: center;
	padding: 2rem 0;
}

.venue_error {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 1.4rem;
	line-height: 1.3em;
}

.venue_error_emoji {
	font-size: 8rem;
	line-height: 1.5em;
}

.venue_error_resolve {
	font-size: 1.75rem;
}
.venue_error_resolve_link {
	font-size: 1.75rem;
}

.area_button {
	height: fit-content;
	width: fit-content;
	padding: 0.8rem 0.8rem;
	font-size: 1.2rem;
	margin: 0.2rem 1rem;
	color: #616161;
}

.area_count {
	border-radius: 100%;
	padding: 0.5rem;
	margin: auto 0 auto 0.5rem;
	font-size: 1rem;
	background-color: grey;
}

.area_button:focus {
	background-color: white;
}

.filter_container {
	display: flex;
	justify-content: flex-start;
}

.filter {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
}

.filter_menu {
	cursor: pointer;
	width: 12rem;
	padding: 0 1rem;
	margin: 0 1.5rem 0 0.2rem;
	border: 0.15rem solid #e0e0e0;
	height: min-content;
	position: relative;
	box-shadow: 3px 3px 6px rgb(220, 220, 220);
	transition: all 0.5s;
}

.filter_menu:hover {
	border: 0.15rem solid #00e5ff;
}

.filter_dropdown_visible {
	display: block;
	position: absolute;
	top: 10;
	left: 0;
	z-index: 30;
	background: white;
	cursor: pointer;
}

.filter_dropdown_visible > div {
	background: white;
	height: fit-content;
	padding: 0 2rem 0.5rem;
	width: 20rem;
}

.filter_option {
	transition: all 0.5s;
}

.filter_option:hover {
	color: #00e5ff;
}

.filter_option_active {
	color: #00e5ff;
}

/********************************************************************
PROPERTY CARD LIST / DISCOVER PAGE
*********************************************************************/
.property_card_container {
	display: -webkit-inline-box;
	width: 100%;
	scrollbar-width: none;
	padding: 8px 0;
	overflow-x: scroll;
}

.property_card_container_discover {
	display: grid;
	grid-template-columns: var(--prop_list_grid_cols);
	gap: 16px 16px;
	/* row-gap: 0.5rem; */
	justify-items: center;
	align-items: center;
	scrollbar-width: none;
}

.property_card_container::-webkit-scrollbar {
	/* display: none; */
}

.property_card {
	height: var(--venue_card_height_min);
	width: var(--venue_card_width_min);
	min-width: var(--venue_card_width_min);
	margin: 12px 12px;
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-columns: 1fr;
	cursor: pointer;
	box-shadow: 2px 3px 6px rgb(200, 200, 200);
}

.property_card:hover {
	transform: scale(var(--venue_card_hov_scale));
	transition-duration: var(--hov_def_duration);
	transition-timing-function: var(--hov_def_transition_ease);
	/* box-shadow: 0 0 8px rgba(50, 50, 50, 0.4); */
	padding: 4px;
	outline: solid 4px rgba(65, 105, 225, 0.9);
}

.property_card_discover {
	margin: 0;
}

.property_card_active {
	padding: 6px;
	outline: 4px solid rgb(48, 79, 254);
	box-shadow: 0 0 8px rgba(50, 50, 50, 0.4);
}

.property_image {
	width: 100%;
	max-height: 80vh;
	object-fit: cover;
	grid-row: 1/-1;
	grid-column: 1/-1;
	z-index: 0;
	content-visibility: auto;
}

.property_overlay {
	grid-row: -1/3;
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 0.6rem 0.7em;
	color: #323232;
	/* clip-path: polygon(69% 55%, 100% 70%, 100% 100%, 0 100%, 0 70%); */
	clip-path: polygon(69% 0%, 100% 22%, 100% 100%, 0px 100%, 0px 25%);
	background: linear-gradient(
		90deg,
		rgba(64, 196, 255, 0.5),
		rgba(24, 255, 255, 1)
	);
	transition: all 1s;
}

.property_overlay_discover {
	grid-row: -1/3;
	grid-column: 1/-1;
	display: flex;
	margin-bottom: -0.8px;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
	padding: 0.6rem 0.7em;
	color: #323232;
	/* clip-path: polygon(70% 60%, 100% 70%, 100% 100%, 0 100%, 0 70%); */
	clip-path: polygon(69% 0%, 100% 12%, 100% 100%, 0px 100%, 0px 15%);
	background: linear-gradient(
		90deg,
		rgba(64, 196, 255, 0.5),
		rgba(24, 255, 255, 1)
	);
	transition: all 1s;
}

.property_overlay:hover,
.property_overlay_discover:hover {
	background: linear-gradient(-90deg, #40c4ff, #18ffff);
}

/*.discover_property_location_icon {
}*/

.venue_card_name {
	margin: 2px 0 6px;
	padding: 0;
	font-size: 1.2rem;
	line-height: 1em;
	font-weight: 700;
}

.venue_card_loc {
	margin: 2px 0 4px;
	padding: 0;
	font-size: 0.75rem;
	line-height: 1em;
}

.venue_card_price {
	margin: 2px 0;
	padding: 0;
	font-size: 0.8rem;
	line-height: 1em;
	/* text-transform: uppercase; */
}

.venue_card_type {
	font-size: 1.1em;
	font-weight: 700;
	/* text-transform: uppercase; */
}

.property_overlay_price_val {
	font-size: 1.1em;
	font-weight: 700;
	line-height: 1.1em;
}

/********************************************************************
PROPERTY DETAILS COMPONENT
*********************************************************************/
.property_details_container {
	max-width: 1280px;
	width: 100%;
	margin: 1rem auto;
	height: max-content;
	display: grid;
	grid-template-columns: 2fr 1fr;
	grid-template-rows: auto;
	justify-content: center;
}

.prop_price_detail {
	font-size: 24px;
	line-height: 1.2em;
	margin: 0;
}

.property_details_form_container {
	grid-column: 2;
	padding: 0 1rem;
}

/***** GALLERY SECTION *****/
.property_details_section {
	grid-column: 1;
	grid-template-columns: auto;
	gap: 1vh;
	overflow-y: visible;
}

.property_details_gallery_section {
	max-width: 960px;
	max-height: 88vh;
	display: grid;
	grid-template-rows: 6fr 2fr;
	gap: 8px;
	box-sizing: border-box;
}

/***** GALLERY COVER IMAGE *****/
.property_image_detail {
	min-height: 70vh;
	height: 100%;
	width: 100%;
	padding: 0;
	grid-column: 1/-1;
	grid-row: 1/2;
	z-index: 0;
	object-fit: cover;
	content-visibility: auto;
	/* outline: solid 2px #2121211a; */
	border-radius: 4px;
	box-shadow: 2px 2px 4px var(--col_pep_3_30);
	filter: contrast(1.1) saturate(1.25);
}

/* .property_image_detail:hover {
	filter: saturate(1.75);
	transform: scale(1.01);
	transition-duration: 0.3s;
	transition-timing-function: ease-in;
} */

.venue_id_mobile {
	grid-column: 1 / -1;
}

.property_content {
	grid-column: 1 / -1;
	content-visibility: auto;
}

.venue_section {
	margin: 0;
	padding: 16px 0 20px;
	border-bottom: solid 1px var(--col_pep_3_15);
}

.property_content_subheading {
	font-size: 1.2rem;
	margin: 4px 0 12px;
}

/***** GALLERY TABS *****/
.property_details_overlay_gallery_tabs {
	margin: 0;
	grid-column: 1 / -1;
	grid-row: 2/-1;
	max-width: 100%;
	width: 100%;
	min-height: 116px;
	max-height: 120px;
	padding-bottom: 4px;
	z-index: 1;
	display: flex;
	align-items: flex-end;
	overflow-x: scroll;
	scrollbar-width: none;
}

.property_details_overlay_gallery_tabs::-webkit-scrollbar {
	/* display: none; */
}

.tab_image {
	min-width: 160px;
	width: 40vw;
	max-width: 180px;
	height: 100%;
	max-height: 116px;
	object-fit: cover;
	object-position: center;
	margin: 0 8px 0 0;
	/* border: 0.5px solid #222222; */
	border-radius: 4px;
	box-shadow: 2px 2px 4px var(--col_pep_3_50);
	cursor: pointer;
}

.tab_image:hover {
	box-shadow: 0 3px 6px var(--col_pep_3_70);
	border-bottom: solid 2px var(--col_pep_3);
	filter: contrast(1.25);
}

/***** NEXT PREV BUTTONS *****/
.property_nav_container {
	grid-column: 2/-1;
	z-index: 1;
	display: flex;
	align-items: flex-end;
	justify-content: space-around;
	width: 100%;
	min-height: 8rem;
	margin-top: -4rem;
}

.property_nav_container > button,
.link_button {
	width: 8rem;
	height: 8rem;
	font-size: 0.8rem;
}

.prev_button {
	background-color: #212121;
	color: #fafafa;
	padding: 1vh;
}

.next_button {
	background-color: #fafafa;
	color: #212121;
	padding: 1vh;
}

.prev_button > h4,
.next_button > h4 {
	font-size: 1.2rem;
	margin: 0;
	padding: 0;
	line-height: 0em;
}

.pointer {
	cursor: pointer;
}

/***** AMMENITIES *****/
.ammenity_container {
	display: flex;
	flex-wrap: wrap;
	content-visibility: auto;
}

.ammenity_card {
	/* width: 8rem;
	min-height: 8rem; */

	margin: 4px 16px 4px 0;

	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
}

.amm_icon {
	margin: 6px auto;
	width: var(--icon_card_size);
	height: var(--icon_card_size);
	padding: 6px;
	/* background-color: gainsboro; */
	background-color: #fafafa;
	box-shadow: 0.1rem 0.1rem 0.3rem gainsboro;
	border-radius: 100%;
	content-visibility: auto;
}

.ammenity_label {
	font-size: 0.8rem;
	line-height: 1.3em;
	text-align: center;
	margin: auto;
}

/***** RULES *****/
.rule_container {
	width: 100%;
	display: grid;

	grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
	/* flex-wrap: wrap; */
}

.rule {
	display: flex;
	min-width: fit-content;
	max-width: 300px;
	align-items: center;
}

.rule_icon {
	/* grid-column: 1; */
	order: 0;
	width: var(--icon_card_size);
	height: var(--icon_card_size);
	padding: 6px;
	margin: 4px 12px 4px 4px;
	background-color: white;
	border-radius: 4rem;
	box-shadow: 0.1rem 0.1rem 0.3rem gainsboro;
	content-visibility: auto;
}

.rule_desc {
	/* grid-column: 2; */
	order: 1;
	font-size: 0.8rem;
	line-height: 1.2em;
}

.rule_label {
	margin: 2px 0;
}

.rule_value {
	margin: 2px 0;
	font-weight: 700;
}

.venue_section_charges_others {
	width: 100%;
	margin: 40px 0 16px;
}

.charges_label {
	display: inline;
}

.charge_value {
	display: inline;
	font-weight: bold;
}

.venue_cancellation_policy {
}

.viewer_container {
	color: #424242;
	padding: 2rem 2rem;
	margin: 0;
}

.venue_map_container * {
	max-width: initial;
	max-height: initial;
}

@media only screen and (min-width: 300px) and (max-width: 1200px) {
	:root {
		--prop_list_grid_cols: repeat(auto-fit, minmax(264px, 1fr));
	}
}

@media only screen and (min-width: 300px) and (max-width: 700px) {
	:root {
		--flex_direction: column;
	}

	.area_filter_container {
		width: 100%;
	}

	.filter_container {
		flex-wrap: wrap;
	}

	.filter {
		margin: auto;
		padding: 8px 0;
		justify-content: space-evenly;
	}

	.property_card_container {
		display: flex;
		align-items: center;
		padding: 8px 0;
		scrollbar-width: initial;
	}

	.property_card_container::-webkit-scrollbar {
		display: initial;
		background-color: #bbdefb;
	}

	.property_details_container {
		grid-template-columns: 2fr;
		grid-template-rows: auto;
		row-gap: 1rem;
		grid: 'image' 'title';
	}

	.property_details_section {
		grid-area: image;
	}

	.property_image_detail {
		min-height: 60vh;
		content-visibility: auto;
	}

	.property_details_overlay_gallery_tabs {
		grid-column: 1/-1;
		/* margin: 0 0 1vh 0; */
	}

	.property_nav_container {
		grid-column: 1/-1;
		align-items: center;
		justify-content: space-evenly;
		margin: 0;
	}

	.property_details_form_container {
		grid-area: title;
		padding: 1vh;
	}

	.viewer_container {
		padding: 2rem 1rem;
	}
}

@media only screen and (min-width: 700px) and (max-width: 1096px) {
	.property_details_container {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid: 'image' 'title';
		row-gap: 1rem;
	}

	.property_details_form_container {
		grid-area: title;
		padding: 1vh;
		line-height: 1.5em;
	}

	.property_details_section {
		grid-area: image;
	}
}
