* {
	/* max-width: 100%;
	max-height: 100%; */
	box-sizing: border-box;
}

body {
	font-size: calc(16px + 10 * ((100vw - 320px) / (1920 - 320)));
	line-height: 1.4em;
	/* content-visibility: auto; */
	/* line-height: calc(1.6em + 0.4 * ((100vw - 320px) / (1920 - 320))); */
	margin: 0;
	padding: 0;
	font-family: 'Poppins', 'Roboto', 'PT Sans', 'Muli', -apple-system,
		BlinkMacSystemFont, 'Segoe UI', 'Noto Sans', 'Oxygen', 'Ubuntu', 'Cantarell',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

/* @import url('https://fonts.googleapis.com/icon?family=Material+Icons&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i&display=swap');

@import url('https://fonts.googleapis.com/css?family=Muli:400,600,700,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Mukta:300,400,600,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,600,700,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css?family=Gothic+A1:200,300,400,500,600,700,900&display=swap'); */

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

/* h1 {
	font-size: 1.4rem;
	margin: unset;
}

h2 {
	font-size: 1.2rem;
	margin: unset;
}

h3 {
	font-size: 1rem;
	margin: unset;
}

h4 {
	font-size: 0.9rem;
	margin: unset;
}

h5 {
	font-size: 0.8rem;
	margin: unset;
}

h6 {
	font-size: 0.75rem;
	margin: unset;
} */

a,
a:visited {
	color: currentColor;
	text-decoration: none;
	border-bottom: solid 2px #00000000;
}

a:hover {
}

audio,
canvas,
progress,
video {
	display: inline-block;
	content-visibility: auto;
}

img {
	max-width: 100%;
	content-visibility: auto;
}

button {
	border-style: none;
	cursor: pointer;
	/* box-shadow: 1px 2px 4px rgba(20, 20, 20, 0.12); */
	padding: 4px 10px;
}

button:hover {
	box-shadow: 2px 4px 8px rgba(20, 20, 20, 0.16);
}

audio:not([controls]) {
	display: none;
	height: 0;
}

ul,
ul > li {
	list-style: none;
	padding: unset;
}

ol,
ol > li {
	padding: unset;
}

legend {
	background-color: #000;
	color: #fff;
	padding: 3px 6px;
}

* {
	scrollbar-color: royalblue;
	scrollbar-track-color: antiquewhite;
}

::-webkit-scrollbar {
	width: 8px;
	height: 10px;
}

::-webkit-scrollbar-thumb {
	background: rgb(65, 105, 225); /* linear-gradient(#16df84, #162432); */
	border-radius: 4px;
}

.bg_WhiteSmoke {
	background-color: #f5f5f5;
}

.pointer {
	cursor: pointer;
}

.debugger {
	background-color: antiquewhite;
	width: 100%;
	margin: 4vh auto;
	padding: 1rem;
	text-align: start;
}

.external_Link {
	color: salmon;
}

.material_icons {
	font-size: inherit;
	color: rebeccapurple;
}

.post:hover {
	background: #16df8480;
}

.highlight {
	padding: 0 2px 0 2px;
	border-bottom: 2px solid #16df84;
	box-shadow: inset 0 -3px 0 #16df84;
}

.outer_link,
.outer_link:visited {
	color: rgb(64, 124, 235);
	font-size: 0.8rem;
	padding: 0.4rem 0;
	text-decoration: underline;
}

@media only screen and (min-width: 481px) {
	.debugger {
		width: 80%;
		padding: 2rem;
		text-align: start;
	}
}

.test_button {
	margin: 1vh 2vh;
	padding: 1vh 2vh;
	background-color: crimson;
	color: whitesmoke;
	font-size: 1rem;
	font-family: 'Roboto Slab';
	font-weight: 700;
	letter-spacing: 1.2px;
}

.WARN {
	color: crimson;
	font-size: small;
	margin: 4px 0;
	line-height: 1.25em;
}

#zsiqwidget {
	/* min-width: 80px;
	min-height: 80px; */
	z-index: 999;
	/* display: block;
	position: absolute; */
}

.zsiq_floatmain {
	visibility: visible;
}

#hubspot-messages-iframe-container * {
	max-width: initial;
	max-height: initial;
	box-sizing: initial;
}

/* .jVAqUn {
	position: fixed;
	bottom: 8px;
	right: 8px;
} */
