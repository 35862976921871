:root {
	--testi_flex_direction: column;
	--media_list_grid_columns: 1fr;
	--feat_list_grid_columns: 1fr 1fr;
	--feat_card_pic_dimen: 140px;
}

.home_container {
	width: 100%;
	min-height: 200vh;
}

.hero_container {
	min-height: 40vh;
	height: var(--hero_height_large);
	display: grid;
	grid-template-rows: 1fr 1fr 1fr;
	grid-template-columns: 1fr 1fr 1fr;
	color: whitesmoke;
	background: linear-gradient(
		60deg,
		rgba(33, 33, 33, 0.65),
		rgba(32, 32, 32, 0.3)
	);
	/* url("https://images.unsplash.com/flagged/photo-1574319523332-4cadaa531506?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1200&q=60"); */
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	filter: saturate(1.4);
}

.hero_img {
	height: 100%;
	grid-row: 1/-1;
	grid-column: 1/-1;
	z-index: 0;
	object-fit: cover;
	object-position: center;
	filter: blur(2px);
	opacity: 1;
	transition: opacity 500ms ease 0s;
	content-visibility: auto;
}

.hero_overlay {
	text-align: start;
	padding: 4%;
	z-index: 1;
	background: linear-gradient(
		60deg,
		rgba(33, 33, 33, 0.65),
		rgba(32, 32, 32, 0.3)
	);
	grid-row: 1/-1;
	grid-column: 1/-1;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	/* backdrop-filter: blur(2px) saturate(1.1) contrast(1.1); */
}

.form_container {
	position: absolute;
	width: 30rem;
	top: 20%;
	right: 5rem;
	display: flex;
	text-align: left;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
	background-color: white;
	z-index: 200;
	padding: 2rem;
	border-radius: 0.5rem;
	box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.15);
}

.form_container > h1 {
	line-height: 2.5rem;
	font-weight: 600;
	font-size: 1.7rem;
	color: #212121;
}

.form_container > img {
	position: absolute;
	top: -10%;
	right: 0;
	margin-right: 3rem;
}

.form {
	width: 100%;
}

.form > form > div {
	margin-top: 1rem;
}

.form > form > div > label {
	text-transform: uppercase;
	font-size: 0.8rem;
}

.form > form > div > input {
	padding: 0.7rem;
	width: 100%;
	font-size: 1.2rem;
	font-family: 'Poppins';
	font-weight: 300;
	border: 0.1rem solid #e0e0e0;
}

::placeholder {
	font-weight: thin;
	color: #9e9e9e;
}

.find_venue_button {
	width: fit-content;
	margin: 1.5rem 0 0;
	padding: 0.6em 1.25em;
	font-size: 1.25rem;
	font-family: 'Poppins';
	font-weight: 600;
	border-radius: 0.3em;
	background-image: var(--grad_pep_23);
	background-size: 150% 100%;
	transition: all 0.5s ease-in-out;
}

.find_venue_button:hover {
	background-position: 300% 20%;
}

@keyframes fadein {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.enter {
	animation: fadein 1200ms;
	opacity: 1;
}

.testimonials_container {
	/* padding: 1vh 2%; */
	width: 100%;
	/* margin-bottom: 10vh; */
	background: linear-gradient(60deg, #002040, #160040);
	background-repeat: no-repeat;
	background-size: 100% 60%;
	background-position: 0% 100%;
}

.testi_header {
	display: flex;
	flex-direction: var(--testi_flex_direction);
	justify-content: space-between;
	align-items: flex-end;
	margin-top: 2vh;
}

.testi_head_title {
	font-size: 2rem;
	font-weight: 700;
	line-height: 1.2em;
	margin-top: 2vh;
}

.testi_head_cta {
	color: cornflowerblue;
}

.test_list_container {
	display: grid;
	width: 100%;
	grid-template-columns: var(--media_list_grid_columns);
	grid-gap: 1rem;
	/* justify-content: space-evenly; */
}

.testimonial_card_container {
	width: 100%;
	/* height: 360px; */
	display: flex;
	flex-direction: column;
	/* margin: 4px; */
	/* padding: 8px; */
	/* background-color: whitesmoke; */
	box-shadow: 2px 1px 4px rgba(33, 33, 33, 0.2);
}

.testimonial_link_external {
	width: 100%;
	height: 100%;
	border-bottom: 0px;
}

.testimonial_link_external:hover {
	background-color: var(--col_pep_1);
}

.testimonial_pic {
	width: 100%;
	min-height: 300px;
	flex-basis: 100%;
	/* flex-grow: 1; */
	object-fit: cover;
	/* margin: 0 0 1vh; */
	/* border: solid 2px #2121211a; */
	cursor: pointer;
}

.testimonial_pic:hover {
	transform: scale(1.02);
	transition-duration: var(--hov_def_duration);
	transition-timing-function: var(--hov_def_transition_ease);
}

.testimonial_msg {
	margin: 0 auto 1vh;
	padding: 0 4px;
	font-family: 'Kalam';
	font-size: 1.1rem;
	/* line-height: 1.3em; */
}

.feature_desc {
	color: whitesmoke;
	font-size: 1.2rem;
}
.feature_cta {
	/* text-transform: uppercase; */
	/* font-weight: 700; */
	color: var(--col_pep_1);
	font-weight: 700;
	letter-spacing: 0.7px;
	filter: drop-shadow(1px 1px 4px var(--col_pep_1_50));
}

.feat_list_container {
	display: grid;
	grid-template-columns: var(--feat_list_grid_columns);
	gap: 2vh;
	margin-top: 2rem;
	/* flex-wrap: wrap; */
	/* justify-content: space-evenly; */
}

.feat_card_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	/* margin: 0 32px 0 0; */
	/* border: solid thin #2121214a; */
}

.feat_card_pic {
	width: var(--feat_card_pic_dimen);
	height: var(--feat_card_pic_dimen);
	display: flex;
	object-fit: cover;
	/* background-color: whitesmoke; */
	border-radius: 100px;
	box-shadow: 1px 1px 3px #2121211a;
}

.feat_card_pic:hover {
	transform: scale(1.05);
	transition-duration: 0.3s;
	transition-timing-function: ease-in;
}

.feat_icon {
	width: 100%;
	height: 100%;
	margin: auto;
	color: #58aade;
}

.feat_icon:hover {
	color: #5ce1e6;
	/* color: #87fff4; */
}

.feat_title {
	margin: 1vh auto;
	text-align: center;
	color: whitesmoke;
	font-size: 1rem;
	font-weight: 500;
}

.home_blogger {
	padding: 2% 2% 4%;
}

.title_constraint {
	margin: 8px 0;
	font-weight: 400;
	font-size: 3.5rem;
	line-height: 1.2em;
}

@media only screen and (max-width: 1096px) {
	.form_container {
		position: inherit;
		display: block;
		width: 90%;
		margin: 0 auto 0 auto;
		padding-bottom: 4rem;
	}

	.form > form {
		display: flex;
		height: 20rem;
		flex-direction: column;
		justify-content: space-evenly;
	}

	.form_container > img {
		display: none;
	}
}

@media only screen and (min-width: 1097px) and (max-width: 1400px) {
	.title_constraint {
		font-size: 2.5rem;
	}
}

@media only screen and (min-width: 481px) {
	:root {
		--testi_flex_direction: row;
		--media_list_grid_columns: 1fr 1fr 1fr;
		--feat_list_grid_columns: 1fr 1fr 1fr 1fr 1fr 1fr;
		--feat_card_pic_dimen: 160px;
	}
}
