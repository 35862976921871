:root {
	--col_pep_1: #5ce1e6;
	--col_pep_1_15: rgba(92, 225, 230, 0.15);
	--col_pep_1_25: rgba(92, 225, 230, 0.25);
	--col_pep_1_30: rgba(92, 225, 230, 0.3);
	--col_pep_1_50: rgba(92, 225, 230, 0.5);
	--col_pep_1_70: rgba(92, 225, 230, 0.7);
	--col_pep_1_75: rgba(92, 225, 230, 0.75);
	--col_pep_1_90: rgba(92, 225, 230, 0.9);
	--col_pep_2: #87fff4;
	--col_pep_2_15: rgba(133, 255, 245, 0.15);
	--col_pep_2_25: rgba(133, 255, 245, 0.25);
	--col_pep_2_30: rgba(133, 255, 245, 0.3);
	--col_pep_2_50: rgba(133, 255, 245, 0.5);
	--col_pep_2_70: rgba(133, 255, 245, 0.7);
	--col_pep_2_75: rgba(133, 255, 245, 0.75);
	--col_pep_2_90: rgba(133, 255, 245, 0.9);
	--col_pep_3: #58aade;
	--col_pep_3_15: rgba(88, 170, 222, 0.15);
	--col_pep_3_25: rgba(88, 170, 222, 0.25);
	--col_pep_3_30: rgba(88, 170, 222, 0.3);
	--col_pep_3_50: rgba(88, 170, 222, 0.5);
	--col_pep_3_70: rgba(88, 170, 222, 0.7);
	--col_pep_3_75: rgba(88, 170, 222, 0.75);
	--col_pep_3_90: rgba(88, 170, 222, 0.9);

	--col_red_deep: #ed5063;
	--col_blue_deep: #004bfe;
	--col_deep_blue: #004bfe;
	--col_green_deep: #00bb5d;
	--col_red: #f18390;
	--col_blue: #40acff;
	--col_green: #4ace8c;
	--col_blue_link: #4f86f7;

	--header_height: 100px;
	--header_height_adjust: -64px;
	--head_background: linear-gradient(
		to bottom,
		rgba(0, 0, 0, 0.9),
		rgba(0, 0, 0, 0.6),
		rgba(0, 0, 0, 0)
	);

	--grad_pep_12: linear-gradient(50deg, var(--col_pep_1), var(--col_pep_2));
	--grad_pep_21: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_1));
	--grad_pep_13: linear-gradient(50deg, var(--col_pep_1), var(--col_pep_3));
	--grad_pep_31: linear-gradient(50deg, var(--col_pep_3), var(--col_pep_1));
	--grad_pep_23: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_3));
	--grad_pep_32: linear-gradient(50deg, var(--col_pep_3), var(--col_pep_2));

	--logo_height: 40px;
	--logo_margin: 4px;
	--nav_line_height: 48px;
	--avatar_height: 44px;

	--hero_height_large: 60vh;
	--hero_height_mid: 60vh;
	--hero_height_small: 60vh;
	--width_cont_maxwidth: 100%;
	--width_cont_padding: 2em 1em;

	--head_margin: 4px 0;
	--head_font_size: 1.8rem;
	--head_font_size_mini: 1rem;
	--head_line_height: 1.2em;
	--head_font_bold: 700;
	--head_font_regular: 500;
	--head_font_thin: 300;

	--nav_padding: 0 4px;
	--nav_grid_cols: 100px auto 160px;
	--nav_grid_rows: var(--nav_line_height) var(--nav_line_height);
	--nav_grid_areas: 'logo . propowner' 'links links links';

	--links_container_width: 100%;
	--links_container_justify: stretch;
	--nav_link_width_max: 100px;
	--nav_link_fontsize: 0.8rem;
	--nav_link_margins: 0 4px;

	--nav_link_contact_width: 100%;
	--nav_link_email_width: 100%;
	--nav_email_align: left;

	--foot_flex_dir: column;
	--foot_flex_padding: 4vh 10px;
	--foot_list_width: 100%;
	--foot_detail: 'f_comp f_comp f_comp' 'f_links f_about f_extras';
	--foot_grid_row: '280px 320px';
	--foot_font_size: 0.8rem;
}

img {
	object-fit: cover;
	object-position: center;
}

.invis {
	display: none;
}

.seperator {
	margin: 0 6px;
}

.app {
	font-family: 'Poppins';
	min-height: 100vh;
	font-size: 1rem;
	background-color: #eeeeee;
	color: rgb(51, 51, 51);
}

.thin {
	font-weight: 300;
}

.regular {
	font-weight: 500;
}

.bold {
	font-weight: 700;
}

.circ_val {
	min-width: 1.25em;
	min-height: 1.25em;
	margin-right: 8px;
	padding: 0.25em;
	display: inline-block;
	text-align: center;
	border-radius: 2em;
	background-color: rgb(230, 230, 230);
}
/* Navbar styles */

.app_header {
	width: 100%;
	height: var(--header_height);
	padding: var(--nav_padding);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	/* flex-direction: column; */
	/* display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-between; */
	display: grid;
	grid-template-columns: var(--nav_grid_cols);
	grid-template-rows: var(--nav_grid_rows);
	grid-template-areas: var(--nav_grid_areas);
	align-items: center;
	justify-items: center;
	justify-content: space-between;
	background: var(--head_background);
	/* background-color: #ffffff5d; */
	/* box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1); */
	z-index: 990;
}

.app_link {
	color: #61dafb;
}

.cont_form_tf {
	min-height: 2rem;
	width: 25ch;
	padding: 2px 0 2px 4px;
}

.cont_form_error {
	color: rgb(255, 104, 104);
	font-size: 0.8rem;
	font-variant-caps: all-small-caps;
}

.logo_container {
	grid-area: logo;
	height: var(--logo_height);
	margin: var(--logo_margin);
	width: 100%;
}

.app_logo {
	min-height: 40px;
	height: var(--logo_height);
	min-width: 80px;
	width: 96%;
	object-fit: contain;
	pointer-events: none;
}

.link_prop_owners,
.link_prop_owners:visited {
	grid-area: propowner;
	/* max-width: 200px; */
	/* margin-left: 8px; */
	/* margin: 0 4px; */
	padding: 1px 12px;
	font-size: 0.9rem;
	color: whitesmoke;
	text-shadow: 0 0 3px rgba(33, 33, 33, 0.4);
	border: solid 1px #00000000;
}

.link_prop_owners:hover {
	border: solid 1px var(--col_pep_2);
	border-radius: 20px;
}

.links_container {
	grid-area: links;
	justify-self: var(--links_container_justify);
	min-width: 312px;
	width: var(--links_container_width);
	padding: 0 4px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: whitesmoke;
}

.app_nav_links {
	display: flex;
	align-items: center;
	height: 100%;
	min-width: 308px;
	width: 100%;
	/* max-width: 320px; */
	justify-content: space-between;
	padding: 0 0 0 8px;
	border-radius: 22px 22px;
	background-color: rgba(34, 34, 34, 0.4);
}

.app_nav_links_noauth {
	/* max-height: 100%; */
	/* min-width: 310px; */
	/* width: 20%; */
	/* height: 100%; */
	/* max-width: 320px; */
	padding: 0 0 0 8px;
	/* display: flex;
	align-items: center;
	justify-content: space-between; */
	border-radius: 22px 22px;
	background-color: rgba(34, 34, 34, 0.5);
}

.nav_links_noauth_container {
	display: flex;
	width: 100%;
	grid-column: 1/-1;
	justify-content: space-between;
}

@supports (backdrop-filter: blur(1)) {
	.app_nav_links {
		background-color: none;
		backdrop-filter: blur(2px) brightness(0.5);
	}
}

.authed_links {
	display: flex;
	flex-basis: 70%;
	padding: 0 8px;
	justify-content: space-between;
	border-right: solid 1px rgba(0, 0, 0, 0.4);
}

.user_avatar_container {
	display: flex;
	height: var(--avatar_height);
	align-items: center;
	justify-content: space-between;
	padding-left: 1vh;
}

.user_avatar {
	height: var(--avatar_height);
	width: var(--avatar_height);
	margin-inline-start: 8px;
	object-fit: cover;
	object-position: 50% 0;
	padding: 4px 4px 0;
	/* background-color: var(--col_pep_1); */
	background: var(--grad_pep_21);
	/* border: solid 1px blueviolet; */
	border-radius: 50%;
}

.user_avatar_noauth {
	background-color: rgba(250, 250, 250, 0.2);
	border: solid 2px var(--col_pep_3);
}

.nav_link {
	font-size: var(--nav_link_fontsize);
	/* max-width: var(--nav_link_width_max); */
	/* word-break: break-all; */
}

.nav_action_icon {
	max-width: 1rem;
	max-height: 1rem;
	margin: 0 8px 0 0;
	color: whitesmoke;
}

.nav_contact {
	/* width: var(--nav_link_contact_width); */
	margin: var(--nav_link_margins);
}

.nav_email {
	/* text-align: var(--nav_email_align); */
	/* width: var(--nav_link_email_width); */
}

.nav_login {
	width: var(--nav_link_login_width);
	min-width: 80px;
	margin-right: 0;
	display: inline-block;
}
/* End of Navbar styles */

.pages_container {
	/* margin-top: var(--header_height_adjust); */
	/* max-width: 1366px; */
}

/* Global Styles */

.width_container {
	max-width: var(--width_cont_maxwidth);
	width: 100%;
	margin: 0 auto;
	padding: var(--width_cont_padding);
	/* outline: dashed 0.1px #ff4400; */
}

.head_mini {
	margin: var(--head_margin);
	font-size: var(--head_font_size_mini);
	font-weight: var(--head_font_regular);
}

.head_bold {
	margin: var(--head_margin);
	font-size: var(--head_font_size);
	line-height: var(--head_line_height);
	font-weight: var(--head_font_bold);
}

.head_thin {
	margin: var(--head_margin);
	font-size: var(--head_font_size);
	line-height: var(--head_line_height);
	font-weight: var(--head_font_thin);
}

.subsec_header {
	margin-bottom: 2rem;
	padding: 1vh 0;

	display: flex;
	align-items: flex-end;
	justify-content: space-between;

	font-size: 1rem;
	line-height: 1.6em;

	border-bottom: solid 2px rgba(33, 33, 33, 0.1);
}

.hover_animation:hover {
	transition-duration: var(--hov_def_duration);
	transition-timing-function: var(--hov_def_transition_ease);
}

/* end of Global Styles */

/* footer styles */
.foot_container {
	display: flex;
	flex-direction: var(--foot_flex_dir);

	/* display: grid;
	grid: var(--foot_detail);
	grid-template-rows: var(--foot_grid_row); */
	/* grid-template-columns: var(--foot_grid_col); */
	row-gap: 1rem;
	column-gap: 5%;

	align-items: stretch;
	justify-content: space-between;
	font-family: 'Poppins';
	font-weight: 300;
	padding: var(--foot_flex_padding);
	background-color: #171717;
	color: whitesmoke;
}

.foot_container > section {
	/* border: dashed 1px peachpuff; */
}

.foot_comp_details {
	/* grid-area: f_comp; */
	width: 40%;
	min-width: 298px;
	max-width: 310px;
}

.foot_lists {
	width: var(--foot_list_width);
	min-width: 320px;
	display: flex;
	justify-content: space-between;
}

.foot_quick_links {
	grid-area: f_links;
}

.foot_about {
	grid-area: f_about;
}

.foot_extras {
	grid-area: f_extras;
}

.foot_comp_social_list {
	display: flex;
	flex-wrap: wrap;
}

.comp_desc {
	font-size: 1em;
	line-height: 1.5em;
}

.soc_link {
	width: 32px;
	height: 32px;
	margin: 8px 16px 8px 0;
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 2px 8px; */
	/* background-color: #171717; */
	border-radius: 40px;
	border: solid 2px royalblue;
}

.soc_icon {
	margin: auto;
}

.fb,
.fb:visited {
	color: royalblue;
	border-color: royalblue;
}

.fb:hover {
	color: whitesmoke;
	background-color: royalblue;
}

.insta,
.insta:visited {
	color: #e92b56;
	border-color: #e92b56;
}

.insta:hover {
	color: whitesmoke;
	background-color: #e92b56;
}

.twit,
.twit:visited {
	color: #0089ec;
	border-color: #0089ec;
}

.twit:hover {
	color: whitesmoke;
	background-color: #0089ec;
}

.yt,
.yt:visited {
	color: crimson;
	border-color: crimson;
}

.yt:hover {
	color: whitesmoke;
	background-color: crimson;
}

.snap,
.snap:visited {
	color: yellow;
	border-color: yellow;
}

.snap:hover {
	color: whitesmoke;
	background-color: rgba(255, 255, 0, 0.85);
}

.in,
.in:visited {
	color: #0079e4;
	border-color: #0079e4;
}

.in:hover {
	color: whitesmoke;
	background-color: #0079e4;
}

.foot_title {
	font-weight: 700;
}

.foot_links_list {
	margin-top: 1vh;
}

.foot_links_list_item {
	color: rgb(180, 180, 180);
	/* margin: 2px 0; */
	padding-bottom: 2px;
	font-size: var(--foot_font_size);
}

.foot_links_list_item:hover {
	color: rgb(245, 245, 245);
	text-decoration: underline;
}
/* end of footer styles */

.price_high {
	color: #2db87a;
}

.text_uppercase {
	text-transform: uppercase;
}

.cbreak_container {
	background-color: aliceblue;
	padding: 1vh;
	min-width: 280px;
	max-width: 312px;
}

.cbreak_pre_discount,
.cbreak_discount_amount,
.cbreak_taxes,
.cbreak_final {
	margin: 8px 0;
	font-size: 1rem;
	line-height: 1.3em;
	display: flex;
}

.cbreak_value {
	margin-left: auto;
	font-weight: 700;
}

.cbreak_final {
	font-size: 1.5rem;
	font-weight: 700;
	letter-spacing: 0.5px;
}

.error_container {
	margin-top: 10vh;
}

.refunds_container > p {
	margin: 2rem 0 1rem;
}

.note {
	color: var(--col_red_deep);
	/* text-shadow: #171717; */
}

@media only screen and (min-width: 481px) {
	:root {
		--header_height: 64px;
		--header_height_adjust: -64px;
		--head_background: linear-gradient(
			to bottom,
			rgba(0, 0, 0, 0.8),
			rgba(0, 0, 0, 0)
		);

		--logo_height: 48px;
		--logo_margin: 8px;
		--avatar_height: 42px;
		--hero_height_large: 360px;
		--hero_height_mid: 320px;
		--hero_height_small: 320px;

		--head_margin: 8px 0 4px;
		--head_font_size: 2.5rem;
		--head_font_size_mini: 0.9rem;
		--head_line_height: 1.2em;
		--head_font_bold: 700;
		--head_font_regular: 500;
		--head_font_thin: 300;

		--width_cont_maxwidth: 1200px;
		--width_cont_padding: 1em 2em;

		--hov_def_duration: 0.3s;
		--hov_def_transition_ease: ease-in-out;

		--nav_padding: 0 2vh;
		--nav_grid_cols: 160px 1fr 350px 160px;
		--nav_grid_rows: 1fr;
		--nav_grid_areas: 'logo . links propowner';

		--links_container_width: auto;
		--links_container_justify: end;
		--nav_link_width_max: auto;
		--nav_link_fontsize: 0.9rem;
		--nav_link_margins: 0 20px;
		--nav_link_contact_width: auto;
		--nav_link_email_width: auto;
		--nav_email_align: left;

		--foot_flex_dir: row;
		--foot_detail: 'f_comp f_links f_about f_extras';
		--foot_grid_row: '360px';
		--foot_font_size: 1rem;
		--foot_flex_padding: 4vh 8vh;
		--foot_list_width: 60%;
	}
}

@media only screen and (min-width: 1401px) {
	:root {
		--foot_flex_padding: 4vh 12%;
	}
}

@media only screen and (min-width: 1601px) {
	:root {
		--foot_flex_padding: 4vh 16%;
	}
}

/* @media (prefers-reduced-motion: no-preference) {
	.app_logo {
		animation: app_logo_spin infinite 20s linear;
	}
} */

/* @keyframes app_logo_spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
} */
