:root {
	--card_width: 304px;
	--card_height: 120px;
}

.booking_form_container {
	background-color: whitesmoke;
}

.booki_list_item_link,
.booki_list_item_link:visited {
	color: var(--col_blue_link);
}

.booki_list_item_link:hover {
	text-decoration: underline;
}

.booki_add {
	margin: 0 0 0 8px;
}

.booking_form {
	/* max-width: 840px; */
	/* display: flex;
	justify-content: space-between; */
}

.form_field_container,
.field_container {
	margin: 10px 0 8px;
}

.booking_details {
	/* margin-right: 4rem; */
}

/* Venue selctor styles */

.venue_selector_container {
	display: flex;
	flex-direction: column;
	margin: 1vh auto 2vh;
	padding: 1vh 0 2rem;
	border-bottom: solid 2px var(--col_pep_3);
}

.venue_selector_filters {
}

.venue_card_lister {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(var(--card_width), 1fr));
	grid-template-rows: repeat(auto-fill, var(--card_height));
	gap: 1vw;
	height: 392px;
	overflow-y: scroll;
}

.venue_card_adm {
	display: flex;
	grid-column: span 1;
	max-width: var(--card_width);
	height: var(--card_height);
	cursor: pointer;
	padding: 3px 4px;
}

.venue_card_adm:hover {
	outline: 2px solid var(--col_green);
}

.venue_card_adm_selected {
	outline: 4px solid var(--col_pep_1);
	box-shadow: 2px 0 8px rgba(34, 34, 34, 0.3);
}

.venue_card_adm_pic {
	height: var(--card_height);
	min-width: 100px;
	/* width: 120px; */
	flex-basis: 30%;
	object-fit: cover;
	border-radius: 4px;
	box-shadow: -2px 0 8px rgba(34, 34, 34, 0.3);
	z-index: 20;
}

.venue_card_adm_info {
	height: calc(var(--card_height) - 8px);
	flex-basis: 70%;
	margin: auto 0;
	padding: 4px 4px 4px 10px;
	display: flex;
	flex-direction: column;
	background-color: white;
	font-size: 1rem;
	line-height: 1.1em;
	border-radius: 0 6px 6px 0;
	z-index: 19;
	box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.2);
}

.venue_card_adm_name {
	margin: 4px 0 0;
	font-size: 1.1rem;
	font-weight: 300;
}

.venue_card_adm_loc {
	margin: 8px 0 0;
	font-size: 0.7rem;
	font-weight: 700;
}

.venue_card_adm_type {
	margin: auto 0 4px;
	font-size: 0.8rem;
	font-weight: 500;
}

.venue_card_adm_cost {
	color: var(--col_pep_3);
}

/* venue selector styles */
.booking_details_container {
	display: grid;
	grid-template-columns: 1fr 400px;
	gap: 1rem;
	justify-content: space-between;
}

.booking_status_card {
	width: 400px;
	background-color: white;
	box-shadow: 2px 2px 8px rgba(34, 34, 34, 0.2);
}

.no_booking {
	margin: auto;
	margin-top: 2rem;
	text-align: center;
}

.booking_status_pic {
	width: calc(100% - 16px);
	height: 320px;
	margin: 8px 8px 0;
	/* border: solid 4px #fff; */
}

.booking_status_venue_info,
.booking_status_booking_info,
.booking_status_actions {
	margin: 0 8px 8px;
	padding: 4px 4px 6px 4px;
	display: flex;
	flex-direction: column;
	/* background-color: white; */
	font-size: 1rem;
	line-height: 1.1em;
	border-bottom: solid 1px rgba(34, 34, 34, 0.15);
	/* box-shadow: 2px 2px 4px rgba(34, 34, 34, 0.2); */
}

.booking_status_booking_info {
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 4px 4px 8px 4px;
}

.books_booking_date,
.books_booking_guests {
	margin: 0;
	font-size: 0.8rem;
}

.books_action_item {
	margin: 6px 0 2px;
}

.books_action_item:hover {
	background-color: whitesmoke;
	/* border-bottom: solid 1px rgba(34, 34, 34, 0.15); */
}

.books_act_item_pair {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 0.9rem;
	margin-bottom: 16px;
}

.books_action_label {
	margin: 0;
	color: #222;
	font-size: 0.8rem;
	font-weight: 700;
	font-variant-caps: all-petite-caps;
}

.books_act_item_val {
	margin: 0;
}

.books_act_item_action {
	background-color: white;
	color: var(--col_green_deep);
	/* font-size: 1rem; */
	margin: 0;
	padding: 0.6em 1.2em;
	border-radius: 6px;
	border: solid 1px var(--col_green_deep);
	box-shadow: 1px 1px 4px rgba(0, 187, 93, 0.3);
}

.books_act_item_warn {
	color: crimson;
	border: solid 1px crimson;
	margin: 8px 0;
}

.books_act_item_action:hover {
	box-shadow: 2px 4px 8px rgba(0, 187, 93, 0.3);
}

.books_act_item_action_disabled {
	color: grey;
	border: solid 1px grey;
	box-shadow: none;
}

.books_act_item_action_disabled:hover {
	box-shadow: none;
}

.customer_details_container {
	/* display: flex; */
	/* justify-content: space-evenly; */
}

.form_submit_button {
	margin: 1rem 0 2em;
	padding: 0.8em 1.6em;
	font-size: 1rem;
	border-radius: 6px;
	/* background-color: var(--col_pep_3); */
	background-image: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_1));
}

@media only screen and (min-width: 481px) {
	:root {
		--card_width: 360px;
	}
}
