.CalendarDay__selected {
	background: rgb(3, 170, 3);
	color: white;
}

.CalendarDay__selected_span {
	background: rgb(5, 200, 5);
	color: white;
}

.CalendarDay__selected:hover {
	background: rgb(44, 225, 44);
	color: white;
}

.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
	background: rgba(252, 106, 106, 0.863);
	color: white;
}

.DayPickerNavigation_button__default {
	min-height: 32px;
}

.DateRangePickerInput {
	background-color: inherit;
}

.DateRangePickerInput_arrow {
	max-width: 16px;
	color: var(--col_pep_3);
	font-size: 1.4rem;
}

.DateInput {
	background-color: inherit;
}

.DateInput_input {
	font-size: 1rem;
	background-color: inherit;
}

.DateRangePickerInput__withBorder_2 {
	border-color: #bfbfbf;
}

.DateInput_input__focused {
	border-color: var(--col_pep_3);
}

.DayPicker_weekHeader_ul {
	max-width: 100%;
	font-size: 1rem;
}

/* .DayPicker_weekHeader_li {
	width: 34.3px !important;
} */
