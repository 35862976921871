:root {
}

.booking_details {
	min-height: 480px;
}

.form_sections_placeholder {
	opacity: 0.1;
	width: 280px;
	height: 200px;
	object-fit: contain;
}

.prop_cost_break {
	margin-top: 1rem;
	width: max-content;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	line-height: 1.1em;
}

.venue_booking_helper {
	margin: 4px 0;
	font-weight: 500;
}

.form_submit_button {
	margin: 0 0 2em;
	padding: 0.8em 1.6em;
	font-size: 1rem;
	border-radius: 6px;
	/* background-color: var(--col_pep_3); */
	background-image: var(--grad_pep_12);
	/* background-image: linear-gradient(50deg, var(--col_pep_2), var(--col_pep_1)); */
}

.booking_form_cost_total {
	margin: 4px 0;
	font-size: 2rem;
	font-weight: 700;
}

.brek_val {
	color: var(--col_green);
	font-weight: 700;
}

.booking_form_cost_taxrate {
	margin: 4px 0 4px auto;
	font-size: 0.9rem;
	/* font-weight: 700; */
}

@media only screen and (min-width: 481px) {
	:root {
	}
}

@media only screen and (min-width: 961px) {
	:root {
	}
}
